<script>
import { mapActions } from 'vuex'

export default {
  name: 'ManagementUserProfileListUsers',
  components: {
    Datatable: () => import('@/components/general/Datatable'),
    Modal: () => import('@/components/general/Modal'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      pagination: {
        query: {},
        filter: {
          profile_id: null
        },

        page: 1,
        limit: 100,
        lastPage: null
      },

      users: [],
      profileName: null
    }
  },

  watch: {
    'pagination.page' () {
      this.setFetching(true)

      this.getUserList().finally(() => {
        this.setFetching(false)
      })
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([
      this.getUserProfile(),
      this.getUserList()
    ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'attemptGetUsersList',
      'attemptManageUser',
      'attemptGetProfile'
    ]),

    close () {
      this.$router.push({ name: 'management.user.profiles' })
    },

    goToUser (idUser) {
      const elem = this.$refs['linkToUser' + idUser]

      elem.$el.click()
    },

    getUserList () {
      this.pagination.filter.profile_id = this.$route.params.id

      return this.attemptGetUsersList(this.pagination).then(data => {
        this.users = this.$store.getters.usersList
        this.pagination.lastPage = data.lastPage

        return true
      })
    },

    getUserProfile () {
      return this.attemptGetProfile(this.$route.params.id).then((data) => {
        this.profileName = data.name

        return true
      })
    },

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    }
  }
}
</script>

<template>
  <Modal
    :active="true"
    :cancel="true"
    @close="close"
  >
    <div class="center management-content user-profile">
      <span class="modal-subtitle">{{ $t('management.user.profiles:users.list.title') }}</span>
      <span class="modal-subtitle profile-name">{{ profileName }}</span>
      <Datatable
        v-if="users && users.length > 0"
        :items="users"
        dark
      >
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('management.user.profiles:users.list.name') }}
            </th>
            <th class="th">
              {{ $t('management.user.profiles:users.list.email') }}
            </th>
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr
            class="highlight"
            title="Acessar"
            @click="goToUser(props.item.id)"
          >
            <td class="td">
              <span class="td-text bolder">
                <RouterLink
                  v-show="false"
                  :ref="'linkToUser' + props.item.id"
                  :to="{ name: props.item.roles.includes('ROLE_ADMIN') ? 'users.admin.manage' : 'users.manage', params: { id: props.item.id } }"
                  target="_blank"
                />
                {{ props.item.name }}
              </span>
            </td>
            <td class="td">
              <span class="td-text">{{ props.item.email || '-' }}</span>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
  </Modal>
</template>

<style>
.management-content.user-profile {
  max-width: 700px;
}

.management-content.user-profile .modal-subtitle {
  text-decoration: none;
}

.management-content.user-profile .modal-subtitle.profile-name {
  margin-bottom: 50px;
  color: #ffffffcc;
}

.management-content.user-profile .td {
  width: 50%;
}

.management-content.user-profile .td-text {
  padding: 8px 0;
}

.highlight:hover {
    background-color: #78b3f7;
    cursor: pointer;
}

a,
a:hover,
a:active {
  color: #FFFFFF;
}
</style>
